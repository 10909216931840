import React from 'react';
import { SEO } from "../components";
import {  Link } from "gatsby";
import { 
     Button,
     Purchase
  } from "../styles/components";


function cancelar(props) {
    return (
        <div>
            <SEO title='Pedido Cancelado' />
            <Purchase>
                <h2>Compra Cancelada</h2>
                <p>Lo siento, Se canceló el pedido</p>
                <span role='img' aria-label='donut'>😒</span>
            
                <Link to='/' >
                    <Button>Volver al Catálogo</Button>
                </Link>
            </Purchase>
        </div>
    );
}

export default cancelar;